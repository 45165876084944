import React, {useEffect} from "react";
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';

const FileList = ({
                      files,
                      fetchFiles,
                      downloadProgress,
                      setDownloadProgress,
                      startDownload,
                      finishDownload,
                      askPin,
                      resetPinPopup,
                  }) => {


    useEffect(() => {
        fetchFiles();
    }, []);

    // Function to decrypt the file
    const decryptFile = async (encryptedFileBlob, pin) => {
        const passwordUtf8 = new TextEncoder().encode(pin);

        const keyMaterial = await window.crypto.subtle.importKey(
            "raw",
            passwordUtf8,
            {name: "PBKDF2"},
            false,
            ["deriveKey"]
        );

        const fileArrayBuffer = await encryptedFileBlob.arrayBuffer();

        // Read salt and IV from the file (first 32 bytes)
        const salt = new Uint8Array(fileArrayBuffer.slice(0, 16));
        const iv = new Uint8Array(fileArrayBuffer.slice(16, 32));
        const encryptedContent = fileArrayBuffer.slice(32);

        const key = await window.crypto.subtle.deriveKey(
            {
                name: "PBKDF2",
                salt: salt,
                iterations: 100000,
                hash: "SHA-256"
            },
            keyMaterial,
            {
                name: "AES-CBC",
                length: 256
            },
            false,
            ["decrypt"]
        );

        // Decrypt the file content
        const decryptedContent = await window.crypto.subtle.decrypt(
            {name: "AES-CBC", iv: iv},
            key,
            encryptedContent
        );

        return new Blob([decryptedContent], {type: "application/octet-stream"});
    };

    // Handle file download and decryption
    const handleDownload = async (file, pin) => {

        console.log(file)

        resetPinPopup();

        startDownload();

        try {
            // Download the encrypted file with progress tracking
            const response = await axios.get(
                `https://gateway.defile.chickenkiller.com/ipfs/${file['hash']}?filename=${file['name']}`,
                {
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent;
                        if (total) {
                            const percentCompleted = Math.round((loaded * 100) / total);
                            setDownloadProgress(percentCompleted);
                        }
                    }
                }
            );

            const decryptedBlob = await decryptFile(response.data, pin);

            // Create a link and download the decrypted file
            const url = window.URL.createObjectURL(decryptedBlob);
            const a = document.createElement("a");
            a.href = url;
            a.download = file['name'].replace('.enc', ''); // Remove ".enc" from the filename
            document.body.appendChild(a);
            a.click();
            a.remove();

            window.URL.revokeObjectURL(url); // Release memory
        } catch (error) {
            console.error("Error downloading or decrypting file:", error);
        } finally {
            finishDownload();
        }
    };

    const columns = [
        {
            field: 'id',
            headerName: 'File',
            width: 300,
        },
        {
            field: 'download',
            headerName: 'Download',
            sortable: false,
            width: 100,
            renderCell:  (value, row) => (
                <DownloadIcon onClick={() => askPin((pin) => handleDownload(value.row.file, pin))} ></DownloadIcon>
            ),
        },
    ];


    const getFiles = (list) => {
        return list.map(f => {
            return {id: f['name'], download: "Download", file: f }
        })
    }

    const paginationModel = { page: 0, pageSize: 15 };



    return (
        <div>
            <h2>Uploaded Files</h2>
            {downloadProgress > 0 && (
                <div>
                    <p>Downloading... {downloadProgress}%</p> {/* Display download progress */}
                    <progress value={downloadProgress / 100}/>
                </div>
            )}
            {/*<ul>
                {files.map((file, index) => (
                    <li key={index}>
                        <button onClick={() => askPin((pin) => handleDownload(file, pin))}>
                            {file['name']}
                        </button>
                    </li>
                ))}
            </ul>*/}
            <Paper sx={{ height: 700, width: '100%' }}>
                <DataGrid
                    rows={getFiles(files)}
                    columns={columns}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                    sx={{ border: 0 }}
                />
            </Paper>
        </div>
    );
};

export default FileList;
