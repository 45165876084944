import React from "react";
import axios from "axios";
import fileStorageContract from "../../contract/fileStorageContract";
import web3 from '../../web3';

const FileUpload = ({
                        setSelectedFile,
                        selectedFile,
                        uploadProgress,
                        setUploadProgress,
                        startUpload,
                        finishUpload,
                        askPin,
                        resetPinPopup,
                    }) => {

    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    // Function to encrypt the file content before upload
    const encryptFile = async (file, password) => {
        //const algorithm = {name: "AES-CBC", length: 256};
        const passwordUtf8 = new TextEncoder().encode(password);
        const keyMaterial = await window.crypto.subtle.importKey(
            "raw",
            passwordUtf8,
            {name: "PBKDF2"},
            false,
            ["deriveKey"]
        );

        const salt = window.crypto.getRandomValues(new Uint8Array(16));
        const iv = window.crypto.getRandomValues(new Uint8Array(16)); // Random IV

        const key = await window.crypto.subtle.deriveKey(
            {
                name: "PBKDF2",
                salt: salt,
                iterations: 100000,
                hash: "SHA-256"
            },
            keyMaterial,
            {
                name: "AES-CBC",
                length: 256
            },
            false,
            ["encrypt"]
        );

        const fileArrayBuffer = await file.arrayBuffer();

        const encryptedContent = await window.crypto.subtle.encrypt(
            {name: "AES-CBC", iv: iv},
            key,
            fileArrayBuffer
        );

        const encryptedFileBlob = new Blob([salt, iv, encryptedContent], {type: "application/octet-stream"});

        return new File([encryptedFileBlob], `${file.name}.enc`);
    };


    const onFileUpload = async (pin) => {
        resetPinPopup();
        if (!selectedFile) return;
        startUpload();

        try {
            // Encrypt the file
            const encryptedFile = await encryptFile(selectedFile, pin);

            const formData = new FormData();
            formData.append("file", encryptedFile);

            const response = await axios.post("https://ipfs.defile.chickenkiller.com/api/v0/add?pin=true", formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                },
            });

            const hash = response.data['Hash'];
            const name = selectedFile.name;
            const size = parseInt(response.data['Size']);

            const accounts = await web3.eth.getAccounts();

            await fileStorageContract.methods.uploadFile(name, hash, size).send({
                from: accounts[0],
            });

            finishUpload();
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div>
            <h2>Upload and Encrypt File</h2>
            <input type="file" onChange={onFileChange}/>
            {selectedFile && (
                <div>
                    <button onClick={() => askPin(onFileUpload) }>Encrypt and Upload</button>
                    <p>Progress: {uploadProgress}%</p>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
